/* Override mantine global CSS settings */

[dir='rtl'] * {
  text-align: right;
}
[dir='rtl'] [dir='ltr'] * {
  text-align: left;
}

/* TODO: replace later with vanilla-extract vars */
:root {
  --direction-multiplier: 1;
}
.hendrx_theme_stage[dir='rtl'] {
  --direction-multiplier: -1;
}

[dir='rtl'] .mantine-Slider-thumb {
  left: auto;
  right: calc(var(--slider-thumb-offset) - var(--slider-thumb-size));
}

[dir='rtl'] [dir='ltr'] .mantine-Slider-thumb {
  left: var(--slider-thumb-offset);
  right: auto;
}

.ProseMirror p.is-editor-empty:first-of-type::before {
  float: unset;
}